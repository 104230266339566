.hidden-on-hover {
  display: none;
  position: absolute;
  right: 0;
}

.my-purchases:hover .hidden-on-hover {
    display: block;
  }

.my-purchases:hover .hidden-on-hover {
  display: block;
}

.my-winning:hover .hidden-on-hover {
  display: block;
}

.my-profiles:hover .hidden-on-hover {
  display: block;
}

.logout:hover .hidden-on-hover {
  display: block;
}

.my-pick:hover .hidden-on-hover {
    display: block;
  }
