.header, .footer {
    position: sticky;
    left: 0;
    right: 0;

}
.header {
    top: 0;
}
.footer {
    bottom: 20;
}