.toast-body {
    display: flex;
    justify-content: center;
    align-items: center;
  }


  /* @keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0.3; }
    100% { opacity: 1.5; }
  }
  
  
  .blink-button {
    animation: blink 1s infinite;
  } */


  @keyframes jump {
    0% {
      transform: scale(1);
      /* box-shadow: 0 1px 2px rgba(0,0,0,.15); */
    }
    100% {
      transform: scale(1.1);
      /* box-shadow: 0 4px 20px rgba(0,0,0,.1); */
    }
  }
  
  
  .blink-button {
    animation: 0.6s jump ease infinite alternate;
  }


  .welcome-animation {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 20px;
    border-radius: 10px;
    font-size: 24px;
    animation: fadeInOut 2s forwards;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  
  @keyframes fadeInOut {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    80% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  .star {
    animation: twinkle 2s infinite;
  }
  
  @keyframes twinkle {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }
  


  .toggle-tabs {
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 5px; */
}

.tabs-container {
    display: flex;
    /* border: 2px solid #3182ce; */
    border-radius: 35px;
    overflow: hidden;
    width: fit-content;
    background: #000;
    position: relative; /* Required for absolute positioning */
}

.tab1 {
    padding: 10px 20px; /* Adjust padding as needed */
    cursor: pointer;
    font-weight: 600;
    color: #fff;
    /* background: #000; */
    border: none;
    outline: none;
    flex: 1;
    text-align: center;
    white-space: nowrap;
    /* transition: background-color 0.7s ease, color 0.7s ease; */
}

.tab1.active {
    /* color: #000; */
    background: #00AB26;
    border-radius: 20px;
    margin: 10px;
}

.tab1-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    transition: transform 0.3s ease; /* Smooth slide transition */
}

.tab-panel {
    flex: 1 0 100%; /* Flex-grow, flex-shrink, flex-basis */
    display: none;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.tab-panel.active {
    display: flex;
}

/* new cssssssssssssssssssssssss */

.card-container {
  display: flex;
  /* flex-direction: column; */
  gap: 20px;
}

.card {
  border: 1px solid black;
  width: 100px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: opacity 1s ease, width 1s ease, margin 1s ease; /* Slow transition */
  cursor: pointer;
}

.card.hidden {
  opacity: 0;
  width: 0;
  margin: 0;
  padding: 0;
  border: 0;
}

button {
  margin-bottom: 20px;
}