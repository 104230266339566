/* src/index.css */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

html, body {
  /* min-height: 100vh;  /*standard definition (as fallback) */
  /* min-height: 100dvh; /*dynamic vh will make the trick on iOS */
}

body {
  /* overflow: hidden; */
}

/* Base styles for header and footer */
/* .header {
  padding: 10px;
} */

.player-footer{
  margin-bottom: 100px;
}

.header {
  padding: 10px 20px;
  width: 100%;
}
 
.header.fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.footer {
  padding: 10px 20px;
  width: 100%;
}
 
.footer.fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}