.custom-select-wrapper {
  position: relative;
  width: 100%;
}

.custom-select {
  appearance: none; /* Remove default arrow */
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  padding-right: 40px; /* Add enough space for the custom arrow */
  position: relative;
  z-index: 1; /* Ensure it sits above the arrow */
  width: 100%; /* Ensure full width */
}

.custom-select-wrapper::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 10px;
  width: 0;
  height: 0;
  pointer-events: none;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #000; /* Color of the arrow */
  transform: translateY(-50%);
  z-index: 0; /* Ensure it sits below the select box */
}
